<template>
  <div style="padding: 30px 30px 0 0;">
    <NForm
      ref="formRef"
      label-placement="left"
      label-width="120px"
      require-mark-placement="left"
      :model="formValue"
      :rules="formRules"
    >
      <NFormItem label="填空答案：" path="answer">
        <NInput
          v-if="formType === formItemTypeVal.INPUT"
          clearable
          placeholder=""
          v-model:value="formValue.answer"
        />
        <NInput
          v-else-if="formType === formItemTypeVal.TEXTAREA"
          type="textarea"
          clearable
          placeholder=""
          v-model:value="formValue.answer"
        />
      </NFormItem>
      <NFormItem label="评分方式：" path="answerMatchMethod">
        <NRadioGroup v-model:value="formValue.answerMatchMethod">
          <NRadio
            v-for="item in answerMatchMethodOptions"
            :key="item.value"
            :value="item.value"
            style="margin-right: 15px;"
          >{{ item.label }}</NRadio>
        </NRadioGroup>
      </NFormItem>
      <SkillsFormItem
        :skills="formValue.skills"
        :autoInitSkillList="false"
        :initSkillList="skillList"
      />
    </NForm>
  </div>
</template>

<script setup>
  import { ref, reactive } from 'vue';
  import { NForm, NFormItem, NInput, NRadioGroup, NRadio } from 'naive-ui';

  import SkillsFormItem from '../SkillsFormItem/SkillsFormItem.vue'

  import { formItemTypeVal, answerMatchMethodValName, generateSkillItem } from '../../generate-data.js';

  defineProps({
    formType: {
      required: true,
      type: Number
    },
    skillList: {
      type: Array,
      default: () => []
    }
  });

  const answerMatchMethodOptions = Object.keys(answerMatchMethodValName).map(item => ({ label: answerMatchMethodValName[item], value: Number(item) }));

  const formRef = ref(null);
  const formValue = reactive({
    answer: null,
    answerMatchMethod: null,
    skills: []
  });
  const formRules = {
    answer: {
      required: true,
      trigger: 'blur',
      message: '请输入答案'
    },
    answerMatchMethod: {
      type: 'number',
      required: true,
      trigger: 'change',
      message: '请选择评分方式'
    }
  };
  function addSkill() {
    formValue.skills.push(generateSkillItem('isCreate'));
  }

  defineExpose({
    setFormValue(data) {
      Object.keys(formValue).forEach(key => {
        formValue[key] = data[key];
      });
      formValue.skills.length === 0 && addSkill();
    },
    getFormValue() {
      return new Promise((resolve, reject) => {
        formRef.value.validate(errors => {
          if (errors) {
            reject();
          } else {
            resolve(formValue);
          }
        });
      });
    }
  });
</script>