<template>
  <div class="form-box">
    <img
      :src="pageData.bgPicURL"
      class="bg-pic"
      @load="handleBgPicLoad"
    />
    <div
      class="drag-box"
      :style="{ width: `${dragBoxSize.w}px`, height: `${dragBoxSize.h}px`, marginLeft: `-${dragBoxSize.w / 2}px`, ...dragBoxBgStyle }"
    >
      <template v-if="dragBoxOnMounted">
        <VueDraggableResizable
          v-for="(item, index) in pageData.formItems"
          :key="item.key"
          parent
          :minWidth="item.minW"
          :minHeight="item.minH"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          v-model:active="item.active"

          :resizable="item.type !== formItemTypeVal.COMMENT"

          @dragstop="(left, top) => handleDragstop(item, left, top)"
          @resizestop="(left, top, width, height) => handleResizestop(item, left, top, width, height)"
        >
          <component
            :is="itemComps[item.type]"
            :itemData="item"
            :class="{ error: item.error }"
          >
            <template #btns>
              <FormItemBtns
                @edit-click="handleEdit(item)"
                @del-click="handleDel(index, item)"
              />
            </template>
          </component>
        </VueDraggableResizable>
      </template>
    </div>
    
    <FormItemSetModal
      ref="formItemSetModalRef"
      :skillList="skillList"
      :pageOptions="pageOptions"
      :isFirstPage="isFirstPage"
      :isLastPage="isLastPage"
    />
  </div>
</template>

<script setup>
  import { ref, reactive, nextTick } from 'vue';
  import VueDraggableResizable from "vue-draggable-resizable-vue3";

  import FormItemInput from './FormItemInput.vue';
  import FormItemTextarea from './FormItemTextarea.vue';
  import FormItemSelect from './FormItemSelect.vue';
  import FormItemRadioGroup from './FormItemRadioGroup.vue';
  import FormItemCheckboxGroup from './FormItemCheckboxGroup.vue';
  import FormItemSubmit from './FormItemSubmit.vue';
  import FormItemComment from './FormItemComment.vue';
  import FormItemBtns from './FormItemBtns.vue';
  import FormItemSetModal from './FormItemSetModal/FormItemSetModal.vue';

  import { formItemTypeVal, generateFormItemData } from '../generate-data.js';
  import { listTypeTagVal } from '@/enumerators/study-situation.js'
  import { resStatusEnum } from '@/enumerators/http.js';

  import { reqList } from '@/api/planning.js';

  const emit = defineEmits(['submit-btn-disabled']);
  const props = defineProps({
    pageData: {
      type: Object,
      default: () => {}
    },
    pageOptions: {
      type: Array,
      default: () => []
    },
    isFirstPage: {
      type: Boolean,
      default: false
    },
    isLastPage: {
      type: Boolean,
      default: false
    }
  });

  const { SUCCESS } = resStatusEnum;

  // let dragInterval = 10;
  const dragBoxSize = reactive({
    w: 2000,
    h: 2000
  });
  const dragBoxBgStyle = {
    background: [
      // `linear-gradient(-90deg, rgba(0, 0, 0, .05) 1px, transparent 1px) 0% 0% / ${dragInterval}px ${dragInterval}px,`,
      // `linear-gradient(rgba(0, 0, 0, .05) 1px, transparent 1px) 0% 0% / ${dragInterval}px ${dragInterval}px`
    ].join(' ')
  };

  const dragBoxOnMounted = ref(false);
  function handleBgPicLoad({ target }) {
    Object.assign(dragBoxSize, {
      w: target.width,
      h: target.height
    });
    if (dragBoxOnMounted.value) {
      nextTick(() => {
        window.dispatchEvent(new Event('resize'));
      });
    } else {
      dragBoxOnMounted.value = true;
    }
  }

  function handleDragstop(item, x, y) {
    Object.assign(item, { x, y });
  }
  function handleResizestop(item, x, y, w, h) {
    Object.assign(item, { x, y, w, h });
  }

  const itemComps = {};
  function initItemComps() {
    itemComps[formItemTypeVal.INPUT] = FormItemInput;
    itemComps[formItemTypeVal.TEXTAREA] = FormItemTextarea;
    itemComps[formItemTypeVal.SELECT] = FormItemSelect;
    itemComps[formItemTypeVal.RADIO_GROUP] = FormItemRadioGroup;
    itemComps[formItemTypeVal.CHECKBOX_GROUP] = FormItemCheckboxGroup;
    itemComps[formItemTypeVal.SUBMIT] = FormItemSubmit;
    itemComps[formItemTypeVal.COMMENT] = FormItemComment;
  }
  initItemComps();

  const formItemSetModalRef = ref(null);
  function handleEdit(item) {
    formItemSetModalRef.value.openEdit(item);
  }
  function handleDel(index, { type }) {
    type === formItemTypeVal.SUBMIT && emit('submit-btn-disabled', false);
    props.pageData.formItems.splice(index, 1);
  }

  const skillList = ref([]);
  function reqSkillList() {
    reqList({
      type: listTypeTagVal.SKILL,
      page: 1,
      page_size: 50000
    }).then(({ code, data }) => {
      const { list } = data;
      if (code === SUCCESS && Array.isArray(list)) {
        skillList.value = list.map(({ title, id, score }) => ({ label: title, value: id, score }));
      }
    });
  }
  reqSkillList();

  defineExpose({
    updateBgPic(picURL) {
      props.pageData.bgPicURL = picURL;
    },
    addInput() {
      props.pageData.formItems.push(
        generateFormItemData('create', {
          type: formItemTypeVal.INPUT
        })
      );
    },
    addTextarea() {
      props.pageData.formItems.push(
        generateFormItemData('create', {
          type: formItemTypeVal.TEXTAREA
        })
      );
    },
    addSelect() {
      props.pageData.formItems.push(
        generateFormItemData('create', {
          type: formItemTypeVal.SELECT
        })
      );
    },
    addRadioGroup() {
      props.pageData.formItems.push(
        generateFormItemData('create', {
          type: formItemTypeVal.RADIO_GROUP
        })
      );
    },
    addCheckboxGroup() {
      props.pageData.formItems.push(
        generateFormItemData('create', {
          type: formItemTypeVal.CHECKBOX_GROUP
        })
      );
    },
    addSubmitBtn() {
      props.pageData.formItems.push(
        generateFormItemData('create', {
          type: formItemTypeVal.SUBMIT
        })
      );
      emit('submit-btn-disabled', true);
    },
    addComment() {
      props.pageData.formItems.push(
        generateFormItemData('create', {
          type: formItemTypeVal.COMMENT
        })
      );
    },
  });
</script>

<style lang="less" scoped>
  @import "~@/styles/variables.less";

  .form-box {
    position: relative;
    text-align: center;
  }

  .bg-pic {
    display: inline-block;
    vertical-align: top;
  }

  .drag-box {
    position: absolute;
    left: 50%;
    top: 0;
    text-align: left;

    :deep(.handle) {
      z-index: 1;
    }

    :deep(.draggable) {
      &:hover {
        .fi-btns {
          display: block;
        }
      }
    }
  }

  .error {
    :deep(.n-input__state-border),
    :deep(.n-base-selection__state-border) {
      border-color: @error-color !important;
    }
    :deep(.n-input:focus) .n-input__state-border,
    :deep(.n-select:focus) .n-base-selection__state-border {
      box-shadow: 0 0 0 2px rgba(208, 48, 80, 0.2) !important;
    }

    :deep(.n-radio__dot) {
      box-shadow: inset 0 0 0 1px @error-color !important;

      &:before {
        background-color: @error-color !important;
      }
    }

    :deep(.n-checkbox.n-checkbox--checked .n-checkbox-box) {
      background-color: @error-color !important;
    }
    :deep(.n-checkbox-box__border) {
      border-color: @error-color !important;
    }

    :deep(.n-button) {
      border: 1px solid @error-color;
    }
  }
</style>