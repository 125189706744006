<template>
  <div style="padding: 30px 0;">
    <NForm
      ref="formRef"
      label-placement="left"
      label-width="115px"
      :model="pageData"
    >
      <NFormItem label="上传视频：" show-require-mark>
        <NButton type="primary" @click="emit('video-content-upload-click')">
          上传文件
          <template #icon>
            <NIcon><FileUploadOutlined /></NIcon>
          </template>
        </NButton>
        <span style="margin-left: 10px; color: #999; font-size: 12px;">支持扩展名：.mp4</span>
      </NFormItem>
      <SkillsFormItem
        :skills="pageData.skills"
      />
      <NFormItem label="预览：">
        <div v-if="pageData.videoData.videoId" style="width: 100%; padding-right: 25px; height: calc(100vh - 300px);">
          <VideoPlayer
            ref="videoPlayerRef"
            controlsShow
          />
        </div>
        <div v-else style="padding: 10px 130px;">
          <NEmpty description="请先上传视频" />
        </div>
      </NFormItem>
    </NForm>
  </div>
</template>

<script setup>
  import { ref, nextTick, watch } from 'vue';
  import { NForm, NFormItem, NButton, NIcon, NSelect, NInputNumber, NEmpty } from 'naive-ui';
  import { FileUploadOutlined } from '@vicons/material';

  import SkillsFormItem from './SkillsFormItem/SkillsFormItem.vue'
  import VideoPlayer from '@/components/VideoPlayer/VideoPlayer.vue';

  import { generateSkillItem } from '../generate-data.js';

  const emit = defineEmits(['video-content-upload-click']);
  const props = defineProps({
    pageData: {
      type: Object,
      default: () => {}
    }
  });

  const formRef = ref(null);
  const videoPlayerRef = ref(null);

  watch(
    () => props.pageData,
    data => {
      if (data && data.videoData && data.videoData.videoId) {
        nextTick(() => {
          videoPlayerRef.value.initVideo(data.videoData.videoId);
        });
      }
    },
    { immediate: true }
  );

  defineExpose({
    updateVideoData(data) {
      Object.assign(props.pageData.videoData, data);
      nextTick(() => {
        videoPlayerRef.value.initVideo(data.videoId);
      });
    }
  });
</script>