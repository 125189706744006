<template>
  <div class="peb" :class="{ disabled }">
    <div @click="handleClick">
      <NIcon class="peb-icon" size="24">
        <component :is="comps[iconName]"></component>
      </NIcon>
      <div class="peb-txt">{{ txt }}</div>
    </div>
  </div>
</template>

<script setup>
  import { NIcon } from 'naive-ui';
  import {
    ImageOutlined,
    TextFieldsFilled,
    ShortTextOutlined,
    ArrowDropDownCircleOutlined,
    RadioButtonCheckedFilled,
    CheckBoxOutlined,
    SaveOutlined,
    CommentOutlined,

    PlayCircleFilledWhiteOutlined,

    MeetingRoomOutlined,
    ChatOutlined,
    FormatListBulletedFilled
  } from '@vicons/material';

  const props = defineProps({
    iconName: {
      required: true,
      type: String
    },
    txt: {
      required: true,
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  });

  const comps = {
    ImageOutlined,
    TextFieldsFilled,
    ShortTextOutlined,
    ArrowDropDownCircleOutlined,
    RadioButtonCheckedFilled,
    CheckBoxOutlined,
    SaveOutlined,
    CommentOutlined,

    PlayCircleFilledWhiteOutlined,

    MeetingRoomOutlined,
    ChatOutlined,
    FormatListBulletedFilled
  };

  function handleClick(e) {
    props.disabled && e.stopPropagation();
  }
</script>

<style lang="less" scoped>
  @import "~@/styles/variables.less";

  .peb {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    transition: color .3s;
    user-select: none;
    cursor: pointer;

    &:hover {
      color: @primary-color;
    }

    &.disabled {
      color: #ccc;
      cursor: not-allowed;
    }

    & + .peb {
      margin-left: 20px;
    }
  }

  .peb-icon {
    vertical-align: top;
  }

  .peb-txt {
    margin-top: -2px;
    font-size: 12px;
  }
</style>