<template>
  <div class="peb-bar">
    <div v-if="pageTypeVal === knowledgeTaskPageTypeVal.FORM">
      <div class="peb-group">
        <PageEditBtn iconName="ImageOutlined" txt="背景图" @click="emit('page-edit-btn-click', 'addBgPic')" />
      </div>
      <div class="peb-group">
        <PageEditBtn iconName="TextFieldsFilled" txt="文本框" :disabled="!bgPicURL" @click="emit('page-edit-btn-click', 'addInput')" />
        <PageEditBtn iconName="ShortTextOutlined" txt="文本域" :disabled="!bgPicURL" @click="emit('page-edit-btn-click', 'addTextarea')" />
        <PageEditBtn iconName="ArrowDropDownCircleOutlined" txt="下拉列表" :disabled="!bgPicURL" @click="emit('page-edit-btn-click', 'addSelect')" />
        <PageEditBtn iconName="RadioButtonCheckedFilled" txt="单选按钮组" :disabled="!bgPicURL" @click="emit('page-edit-btn-click', 'addRadioGroup')" />
        <PageEditBtn iconName="CheckBoxOutlined" txt="复选按钮组" :disabled="!bgPicURL" @click="emit('page-edit-btn-click', 'addCheckboxGroup')" />
      </div>
      <div class="peb-group">
        <PageEditBtn iconName="SaveOutlined" txt="提交按钮" :disabled="!bgPicURL || submitBtnDisabled" @click="emit('page-edit-btn-click', 'addSubmitBtn')" />
      </div>
      <div class="peb-group">
        <PageEditBtn iconName="CommentOutlined" txt="注释" :disabled="!bgPicURL" @click="emit('page-edit-btn-click', 'addComment')" />
      </div>
      <div class="peb-group right">
        <PageEditBtn iconName="PlayCircleFilledWhiteOutlined" txt="预览" @click="emit('page-edit-btn-click', 'preview')" />
      </div>
    </div>
    <div v-else-if="pageTypeVal === knowledgeTaskPageTypeVal.CONVERSATION">
      <div class="peb-group">
        <PageEditBtnDropdown
          iconName="MeetingRoomOutlined"
          txt="设置场景"
          :selectOptions="conversationScenesOptions"
          @option-select="key => emit('page-edit-btn-click', 'addScenes', key)"
        />
        <PageEditBtnDropdown
          iconName="ChatOutlined"
          txt="添加对话"
          :selectOptions="conversationPeopleOptions"
          @option-select="key => emit('page-edit-btn-click', 'addChat', key)"
        />
        <PageEditBtnDropdown
          iconName="FormatListBulletedFilled"
          txt="添加问题"
          :selectOptions="conversationQuesOptions"
          @option-select="key => emit('page-edit-btn-click', 'addQues', key)"
        />
      </div>
      <!--
      <div class="peb-group right">
        <PageEditBtn iconName="PlayCircleFilledWhiteOutlined" txt="预览" @click="emit('page-edit-btn-click', 'preview')" />
      </div>
      -->
    </div>
  </div>
</template>

<script setup>
  import { h } from 'vue';
  import { NIcon } from 'naive-ui';
  import { knowledgeTaskPageTypeVal } from '@/enums/knowledge-task-page-type.js';

  import PageEditBtn from './PageEditBtn.vue';
  import PageEditBtnDropdown from './PageEditBtnDropdown.vue';

  import {
    conversationScenesValName,
    conversationScenesValBg,
    conversationPeopleHumanValName,
    conversationPeopleNarrationValName,
    conversationPeopleValName,
    conversationPeopleValAvatar,
    conversationQuesValName,
    conversationQuesValIconComp
  } from '../generate-data.js';

  const emit = defineEmits(['page-edit-btn-click']);
  const props = defineProps({
    pageTypeVal: {
      required: true,
      type: Number
    },
    bgPicURL: {},
    submitBtnDisabled: {
      type: Boolean,
      default: false
    }
  }); 

  const conversationEditBtnRenderIcon = iconComp => () => h(NIcon, null, { default: () => h(iconComp) });
  const conversationEditBtnRenderThumbnail = thumbnailObj => () => h('img', { style: 'width: 24px; height: 24px; object-fit: contain;', src: thumbnailObj });
  const conversationScenesOptions = Object.keys(conversationScenesValName).map(key => ({ label: conversationScenesValName[key], key: Number(key), icon: conversationEditBtnRenderThumbnail(conversationScenesValBg[key]) }));
  const conversationPeopleOptions = [
    ...Object.keys(conversationPeopleHumanValName).map(key => ({ label: conversationPeopleHumanValName[key], key: Number(key), icon: conversationEditBtnRenderThumbnail(conversationPeopleValAvatar[key]) })),
    ...Object.keys(conversationPeopleNarrationValName).map(key => ({ label: conversationPeopleNarrationValName[key], key: Number(key), icon: conversationEditBtnRenderThumbnail(conversationPeopleValAvatar[key]) }))
  ];
  const conversationQuesOptions = Object.keys(conversationQuesValName).map(key => ({ label: conversationQuesValName[key], key: Number(key), icon: conversationEditBtnRenderIcon(conversationQuesValIconComp[key]) }));
</script>

<style lang="less" scoped>
  .peb-bar {
    padding: 8px 25px;
    background-color: #f5f5f5;
  }

  .peb-group {
    display: inline-block;
    vertical-align: top;

    & + .peb-group {
      margin-left: 55px;
    }

    &.right {
      float: right;
    }
  }
</style>