import { knowledgeTaskPageTypeVal } from '@/enums/knowledge-task-page-type.js';
import {
  formItemTypeVal,
  conversationEditBtnTypeVal,
  conversationQuesVal,
  conversationItemTypeVal
} from './generate-data.js';

const generateReqPageData = {};

function generateReqSkills(skills) {
  const reqSkills = {};
  skills.forEach(({ skillID, score }) => {
    reqSkills[skillID] = score;
  });
  return reqSkills;
}

function generateFormPageFormItemReqData(formItemData, pageList) {
  const { type, x, y, minW, minH, w, h, skills, answer, answerMatchMethod, options } = formItemData;
  let { behaviour } = formItemData;
  const reqData = {
    type,
    introduce: JSON.stringify({ x, y, minW, minH, w, h }),
    ques_answer: Array.isArray(answer) ? answer : [answer]
  };

  skills && (reqData.skill = generateReqSkills(skills));

  switch (type) {
    case formItemTypeVal.INPUT:
    case formItemTypeVal.TEXTAREA:
      reqData.judge_type = answerMatchMethod;
      break;
    case formItemTypeVal.SELECT:
    case formItemTypeVal.CHECKBOX_GROUP:
    case formItemTypeVal.RADIO_GROUP:
      reqData.ques_select = options.map(({ label, value }) => `${value}.${label}`);
      break;
    case formItemTypeVal.COMMENT:
      break;
    case formItemTypeVal.SUBMIT:
      switch (behaviour) {
        case 'prev':
        case 'next':
          break;
        default:
          behaviour = pageList.findIndex(({ key }) => key === behaviour);
      }
      reqData.introduce = JSON.stringify({ x, y, minW, minH, w, h, behaviour });
      break;
  }

  return reqData;
}
function generateFormPageNotePadReqData({ type, title, content }) {
  return {
    type,
    introduce: JSON.stringify({ title, content }),
  };
}
generateReqPageData[knowledgeTaskPageTypeVal.FORM] = (pageData, pageList) => {
  const { pageTypeVal, bgPicURL, formItems, notePadArr } = pageData;
  return {
    data_type: pageTypeVal,
    file_url: bgPicURL,
    data: [
      ...formItems.map(item => generateFormPageFormItemReqData(item, pageList)),
      ...notePadArr.map(item => generateFormPageNotePadReqData(item))
    ]
  };
};

function generateConversationItemReqData(itemData) {
  const { editBtnType, optionVal, talkTxt, content, talkFile, skills, ques, ans, options, savedID } = itemData;
  const reqData = {};
  switch (editBtnType) {
    case conversationEditBtnTypeVal.CHAT:
      Object.assign(reqData, {
        type: conversationItemTypeVal.CONVERSATION,
        people: optionVal,
        // file_url: talkFile.fileUrl,
        title: talkTxt,
        // content
      });
      break;
    case conversationEditBtnTypeVal.QUES_ANS:
      Object.assign(reqData, {
        title: ques,
        skill: generateReqSkills(skills)
      });
      
      switch (optionVal) {
        case conversationQuesVal.QUES_ANS:
          Object.assign(reqData, {
            type: conversationItemTypeVal.QUES_ANS,
            ques_answer: [ans]
          });
          break;
        case conversationQuesVal.SELECT:
          Object.assign(reqData, {
            type: conversationItemTypeVal.SELECT,
            ques_select: options.map(({ label, value }) => `${label}.${value}`),
            ques_answer: options.filter(({ checked }) => checked).map(({ label }) => label)
          });
          break;
      }
      break;
  }
  savedID && (reqData.id = savedID);
  return reqData;
}
generateReqPageData[knowledgeTaskPageTypeVal.CONVERSATION] = pageData => {
  const { pageTypeVal, scenesType, items } = pageData;
  return {
    data_type: pageTypeVal,
    scenes: scenesType,
    data: items.map(item => generateConversationItemReqData(item))
  };
};

generateReqPageData[knowledgeTaskPageTypeVal.VIDEO] = pageData => {
  const { pageTypeVal, videoData, skills } = pageData;
  return {
    data_type: pageTypeVal,
    data: {
      file_url: videoData.fileUrl,
      skill: generateReqSkills(skills)
    }
  };
};

generateReqPageData[knowledgeTaskPageTypeVal.PIC_TXT] = pageData => {
  const { pageTypeVal, htmlValue, skills } = pageData;
  return {
    data_type: pageTypeVal,
    data: {
      detail: htmlValue,
      skill: generateReqSkills(skills)
    }
  };
};

generateReqPageData[knowledgeTaskPageTypeVal.EXERCISES] = pageData => {
  const { pageTypeVal, exercises, skills } = pageData;
  return {
    data_type: pageTypeVal,
    data: {
      skill: generateReqSkills(skills),
      exercises_id: exercises.map(({ id }) => id)
    }
  };
};

export function generateReqData({ knowledgeID, recommendMins, pageList }) {
  return {
    'Knowledge[id]': knowledgeID,
    'Knowledge[advice_time]': recommendMins,
    'Knowledge[all_data]': pageList.map(item => generateReqPageData[item.pageTypeVal](item, pageList))
  };
}