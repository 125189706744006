<template>
  <div class="tpc-box">
    <PageEditBtnBar
      v-show="editBtnBarShow"
      :pageTypeVal="pageData.pageTypeVal"
      :bgPicURL="pageData.bgPicURL"
      :submitBtnDisabled="submitBtnDisabled"
      @page-edit-btn-click="handlePageEditBtnClick"
    />
    <div
      :style="{ paddingRight: pageData.notePadArr ? '65px' : '0' }"
    >
      <NScrollbar
        x-scrollable
        :style="{ height: `calc(100vh - ${editBtnBarShow ? 296 : 239}px)` }"
      >
        <component
          :is="comps[pageData.pageTypeVal]"
          ref="contentRef"
          :pageData="pageData"
          :pageOptions="pageOptions"
          :isFirstPage="isFirstPage"
          :isLastPage="isLastPage"

          @submit-btn-disabled="submitBtnDisabled = false"

          @conversation-content-upload-click="handleClickConversationContentUpload"
          @video-content-upload-click="handleClickVideoContentUpload"
          @pic-txt-content-pic-select="handleClickPicTxtContentPicSelect"
          @conversation-item-del="emit('conversation-item-del', $event)"
        ></component>
      </NScrollbar>
    </div>
    <NotePad
      v-if="pageData.notePadArr"
      class="notepad"
      :notePadArr="pageData.notePadArr"
    />

    <PageLoading :loading="loading" />
    <DirectUpload
      ref="directUploadRef"
      :accept="uploadAcceptStr"
      :maxBytesSize="uploadMaxBytes"
      :uploadProgressShow="uploadProgressShow"
      @upload-start="handleUploadStart"
      @upload-success="handleUploadSuccess"
      @upload-error="handleUploadError"
    />
  </div>
</template>

<script setup>
  import { ref, watch, computed } from 'vue';
  import { NScrollbar, useMessage } from 'naive-ui';

  import PageLoading from '@/components/PageLoading/index.vue';
  import PageEditBtnBar from './PageEditBtnBar.vue';
  import FormContent from './FormContent.vue';
  import NotePad from './NotePad/NotePad.vue';
  import ConversationContent from './ConversationContent.vue';
  import VideoContent from './VideoContent.vue';
  import PicTxtContent from './PicTxtContent.vue';
  import ExercisesContent from './ExercisesContent.vue';
  import DirectUpload from '@/components/DirectUpload/DirectUpload.vue';

  import { resStatusEnum } from '@/enumerators/http.js';
  import { knowledgeTaskPageTypeVal } from '@/enums/knowledge-task-page-type.js';
  import { formItemTypeVal } from '../generate-data.js';
  import { validatePage } from '../data-validator.js';

  import { PostBannerTempUpload } from '@/api/banner.js';

  const emit = defineEmits(['conversation-item-del']);
  const props = defineProps({
    pageData: {
      type: Object,
      default: () => {}
    },
    pageOptions: {
      type: Array,
      default: () => []
    },
    isFirstPage: {
      type: Boolean,
      default: false
    },
    isLastPage: {
      type: Boolean,
      default: false
    }
  });

  const { SUCCESS } = resStatusEnum;
  const loading = ref(false);
  const message = useMessage();

  const contentRef = ref(null);

  const directUploadRef = ref(null);
  const uploadAcceptStr = ref('.jpg,.jpeg,.png');
  const uploadMaxBytes = ref(5 * 1024 * 1024);
  const uploadProgressShow = ref(false);
  let tempUploadType = -1;
  let uploadSuccessCb = function () {}

  const editBtnBarShow = computed(() => [
    knowledgeTaskPageTypeVal.FORM,
    knowledgeTaskPageTypeVal.CONVERSATION
  ].includes(props.pageData.pageTypeVal));
  function handlePageEditBtnClick(type, val) {
    const comp = contentRef.value;
    switch (type) {
      case 'addBgPic':
        uploadAcceptStr.value = '.jpg,.jpeg,.png';
        uploadMaxBytes.value = 5 * 1024 * 1024;
        uploadProgressShow.value = false;
        tempUploadType = 5;
        uploadSuccessCb = function ({ fileUrl }) {
          contentRef.value.updateBgPic(fileUrl);
        }

        directUploadRef.value.openSelectFile();
        break;
      case 'preview':
        const data = props.pageData;
        const valid = validatePage[data.pageTypeVal](data);
        if (valid) {
          window.localStorage.setItem('a-knowledge-preview', JSON.stringify({
            knowledgeID: 0,
            recommendMins: null,
            pageList: [props.pageData]
          }));
          window.open('/full-page/knowledge-preview');
        } else {
          message.error(data.errMsg);
        }
        break;
      default:
        comp && comp[type] && comp[type](val);
    }
  }

  const submitBtnDisabled = ref(false);
  /*
  watch(
    () => props.pageData,
    data => {
      if (data.pageTypeVal === knowledgeTaskPageTypeVal.FORM) {
        let disabled = data.formItems.some(({ type }) => type === formItemTypeVal.SUBMIT);
        submitBtnDisabled.value = disabled;
      }
    },
    { immediate: true }
  );
  */

  const comps = {};
  function initComps() {
    comps[knowledgeTaskPageTypeVal.FORM] = FormContent;
    comps[knowledgeTaskPageTypeVal.CONVERSATION] = ConversationContent;
    comps[knowledgeTaskPageTypeVal.VIDEO] = VideoContent;
    comps[knowledgeTaskPageTypeVal.PIC_TXT] = PicTxtContent;
    comps[knowledgeTaskPageTypeVal.EXERCISES] = ExercisesContent;
  }
  initComps();

  function handleUploadStart() {
    if (!uploadProgressShow.value) {
      loading.value = true;
    }
  }
  function handleUploadSuccess({ ext, fileType, fileUrl, name, size, videoId }) {
    if (tempUploadType === -1) {
      uploadSuccessCb && uploadSuccessCb({ ext, fileType, fileUrl, name, size, videoId });
      if (!uploadProgressShow.value) {
        loading.value = false;
      }
      return;
    }
    PostBannerTempUpload({
      'Banner[type]': tempUploadType,
      'Banner[upload][file_url]': fileUrl,
      'Banner[upload][file_name]': name,
      'Banner[upload][file_size]': size,
      'Banner[upload][extension]': ext,
      'Banner[upload][file_type]': fileType,
      'Banner[upload][video_id]': videoId || ''
    }).then(({ code }) => {
      if (code === SUCCESS) {
        uploadSuccessCb && uploadSuccessCb({ ext, fileType, fileUrl, name, size, videoId });
      }
    }).finally(() => {
      if (!uploadProgressShow.value) {
        loading.value = false;
      }
    });
  }
  function handleUploadError() {
    if (!uploadProgressShow.value) {
      loading.value = false;
    }
  }

  function handleClickConversationContentUpload() {
    uploadAcceptStr.value = '.mp3';
    uploadMaxBytes.value = 100 * 1024 * 1024;
    uploadProgressShow.value = true;
    tempUploadType = 3;
    uploadSuccessCb = function (data) {
      contentRef.value.updateTalkFileData(data);
    }

    directUploadRef.value.openSelectFile();
  }
  function handleClickVideoContentUpload() {
    uploadAcceptStr.value = '.mp4';
    uploadMaxBytes.value = 300 * 1024 * 1024;
    uploadProgressShow.value = true;
    tempUploadType = 4;
    uploadSuccessCb = function (data) {
      contentRef.value.updateVideoData(data);
    }

    directUploadRef.value.openSelectFile();
  }
  function handleClickPicTxtContentPicSelect() {
    uploadAcceptStr.value = '.jpg,.jpeg,.png,.gif';
    uploadMaxBytes.value = 5 * 1024 * 1024;
    uploadProgressShow.value = false;
    tempUploadType = -1;
    uploadSuccessCb = function ({ fileUrl }) {
      contentRef.value.insertAPic(fileUrl);
    }

    directUploadRef.value.openSelectFile();
  }

  defineExpose({
    validateContent() {
      const comp = contentRef.value;
      comp && comp.validate && comp.validate();
    }
  });
</script>

<style lang="less" scoped>
  .tpc-box {
    position: relative;
    border: 1px solid #efeff5;
  }

  .notepad {
    position: absolute;
    top: 60px;
    bottom: 0;
    right: 0;
    background-color: #f5f5f5;
  }
</style>