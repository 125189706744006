<template>
  <div style="padding: 30px 30px 0 0;">
    <NForm
      ref="formRef"
      label-placement="left"
      label-width="120px"
      require-mark-placement="left"
      :model="formValue"
      :rules="formRules"
    >
      <template v-if="formType === formItemTypeVal.COMMENT">
        <NFormItem
          :label="formItemLabel"
          path="answer"
        >
          <NInput
            type="textarea"
            maxlength="100"
            show-count
            clearable
            placeholder=""
            v-model:value="formValue.answer"
          />
        </NFormItem>
      </template>
      <template v-else-if="formType === formItemTypeVal.SUBMIT">
        <NFormItem
          :label="formItemLabel"
        >
          <div>
            <NInput
              clearable
              placeholder=""
              v-model:value="formValue.answer"
            />
            <div style="position: absolute; color: #999; font-size: 12px;">若按钮无名称，则透明显示</div>
          </div>
        </NFormItem>
        <NFormItem
          label="跳转页面"
          path="behaviour"
        >
          <NSelect
            :options="behaviourOptions"
            clearable
            v-model:value="formValue.behaviour"
          />
        </NFormItem>
      </template>
    </NForm>
  </div>
</template>

<script setup>
  import { ref, reactive, computed } from 'vue';
  import { NForm, NFormItem, NInput, NSelect } from 'naive-ui';

  import { formItemTypeVal } from '../../generate-data.js';

  const props = defineProps({
    formType: {
      required: true,
      type: Number
    },
    pageOptions: {
      type: Array,
      default: () => []
    },
    isFirstPage: {
      type: Boolean,
      default: false
    },
    isLastPage: {
      type: Boolean,
      default: false
    }
  });

  const formRef = ref(null);
  const behaviourOptions = computed(() => {
    const arr = [];
    arr.push({ label: '下一页', value: 'next' });
    arr.push(...props.pageOptions);
    props.isFirstPage || arr.push({ label: '上一页', value: 'prev' });
    return arr;
  });
  const formValue = reactive({
    answer: null,
    behaviour: null
  });
  const formRules = {
    answer: {
      required: true,
      trigger: 'blur',
      message: '必填'
    },
    behaviour: {
      required: true,
      trigger: 'change',
      message: '必选'
    }
  };
  const formItemLabel = computed(() => {
    switch (props.formType) {
      case formItemTypeVal.SUBMIT:
        return '按钮名称';
      case formItemTypeVal.COMMENT:
        return '注释内容：';
    }
  });

  defineExpose({
    setFormValue(data) {
      Object.keys(formValue).forEach(key => {
        formValue[key] = data[key];
      });
    },
    getFormValue() {
      return new Promise((resolve, reject) => {
        formRef.value.validate(errors => {
          if (errors) {
            reject();
          } else {
            resolve(formValue);
          }
        });
      });
    }
  });
</script>