<template>
  <div>
    <NCard
      size="small"
      :segmented="{ content: true }"
      :style="{ visibility: pageInited ? 'visible' : 'hidden' }"
    >
      <template #header>
        <div style="margin: -4px -1px;">{{ knowledgeTitle }}</div>
      </template>
      <template #header-extra>
        <div style="margin: -4px -1px;">
          <RecommendMins v-model:mins="taskData.recommendMins" />
          <SkillScoreSummary :pageList="taskData.pageList" />
        </div>
      </template>
      <template #default>
        <div
          style="position: relative; height: calc(100vh - 228px);"
          :style="{ 'padding-left': `${pageListWidth + 12}px` }"
        >
          <TaskPageList
            style="position: absolute; left: 0; top: 0;"
            :listBoxWidth="pageListWidth"
            :pageList="taskData.pageList"
            :currentPageIndex="currentPageIndex"
            @page-add="handlePageAdd"
            @current-page-index-change="handleCurrentPageIndexChagne"
            @confirm-page-del="handlePageDel"
          />
          <div v-if="currentPageIndex === -1" style="padding-top: 100px;">
            <NEmpty description="选择一个页面开始">
              <template #icon>
                <NIcon>
                  <NotStartedOutlined />
                </NIcon>
              </template>
            </NEmpty>
          </div>
          <TaskPageContent
            v-else
            ref="taskPageContentRef"
            :pageData="currentPageData"
            :pageOptions="pageOptions"
            :isFirstPage="isFirstPage"
            :isLastPage="isLastPage"

            @conversation-item-del="handleConversationItemDel"
          />
        </div>
        <div style="margin-top: 12px;">
          <NButton size="large" @click="handleBack">返回</NButton>
          <NButton
            size="large"
            type="primary"
            style="margin-left: 12px; width: 120px;"
            @click="handleClickSave"
          >保存</NButton>
        </div>
      </template>
    </NCard>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive, computed } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { NCard, NButton, NEmpty, NIcon, useMessage } from 'naive-ui';
  import { NotStartedOutlined } from '@vicons/material';

  import PageLoading from '@/components/PageLoading/index.vue';
  import RecommendMins from './components/RecommendMins.vue';
  import SkillScoreSummary from './components/SkillScoreSummary.vue';
  import TaskPageList from './components/TaskPageList.vue';
  import TaskPageContent from './components/TaskPageContent.vue';

  import { resStatusEnum } from '@/enumerators/http.js';
  import { generatePageItemData, generateDefaultTaskData, generateTaskData, exercisesContentExerciseIDtoData, formItemTypeVal } from './generate-data.js';
  import { validateTaskData, tipCurPageErr } from './data-validator.js';
  import { generateReqData } from './req-data.js';
  import { knowledgeTaskPageTypeVal, knowledgeTaskPageTypeValTitle } from '@/enums/knowledge-task-page-type.js';

  import { reqKnowledgeTaskDetail, editKnowledgeTask } from '@/api/knowledge.js'

  const { SUCCESS } = resStatusEnum;
  const message = useMessage();
  const router = useRouter();
  const route = useRoute();
  const loading = ref(false);
  const pageInited = ref(true);

  const pageListWidth = 180;

  const knowledgeID = Number(route.query.id) || 0;
  const knowledgeTitle = ref('知识点');
  const currentPageIndex = ref(-1);
  const currentPageData = ref(null);
  const taskData = reactive(generateDefaultTaskData(knowledgeID));

  function handleCurrentPageIndexChagne(index) {
    currentPageIndex.value = index;
    if (currentPageData.value !== taskData.pageList[index]) {
      currentPageData.value = taskData.pageList[index];
      currentPageData.value && (currentPageData.value.error = false);
    }
  }

  function initKnowledgeInfo() {
    if (!knowledgeID) {
      return;
    }
    loading.value = true;
    reqKnowledgeTaskDetail({
      'Knowledge[id]': knowledgeID
    }).then(({ code, data }) => {
      if (code === SUCCESS) {
        pageInited.value = true;
        knowledgeTitle.value = data.title;
        Object.assign(taskData, generateTaskData(knowledgeID, data));
        exercisesContentExerciseIDtoData(taskData);

        taskData.pageList.length > 0 && handleCurrentPageIndexChagne(0);
      }
    }).finally(() => {
      loading.value = false;
    });
  }

  function handlePageAdd(pageTypeVal) {
    taskData.pageList.push(generatePageItemData('isCreate', pageTypeVal));
  }
  function handlePageDel(index) {
    const curIndex = currentPageIndex.value;
    const delPageData = taskData.pageList.splice(index, 1)[0];

    if (delPageData.pageTypeVal === knowledgeTaskPageTypeVal.CONVERSATION) {
      if (Array.isArray(delPageData.items)) {
        delPageData.items.forEach(item => {
          handleConversationItemDel(item);
        });
      }
    }     

    if (curIndex === index) {
      handleCurrentPageIndexChagne(-1);
    } else if (curIndex > index) {
      handleCurrentPageIndexChagne(curIndex - 1);
    }

    const { key: delPageKey } = delPageData;
    taskData.pageList.forEach(({ pageTypeVal, formItems }) => {
      if (pageTypeVal === knowledgeTaskPageTypeVal.FORM) {
        formItems.forEach((item) => {
          const { type, behaviour } = item;
          if (type === formItemTypeVal.SUBMIT && behaviour === delPageKey) {
            item.behaviour = null;
            item.valid = false;
          }
        });
      }
    });
  }

  const taskPageContentRef = ref(null);
  function handleBack() {
    router.push('/knowledge/index/list');
  }
  function handleClickSave() {
    if (validateTaskData(taskData)) {
      loading.value = true;
      editKnowledgeTask({
        ...generateReqData(taskData),
        delete_dialogue_ids: delete_dialogue_ids.value
      }).then(({ code, data }) => {
        if (code === SUCCESS) {
          message.success('保存成功');
          handleBack();
        }
      }).finally(() => {
        loading.value = false;
      });
    } else {
      tipCurPageErr(currentPageData.value);
      const comp = taskPageContentRef.value;
      comp && comp.validateContent();
    }
  }

  const isFirstPage = computed(() => currentPageIndex.value === 0);
  const isLastPage = computed(() => currentPageIndex.value === taskData.pageList.length - 1);
  const pageOptions = computed(() => {
    const currentPageDataLink = currentPageData.value;
    const arr = [];
    taskData.pageList.forEach((item, index) => {
      if (currentPageDataLink !== item) {
        const { key, pageTypeVal } = item;
        arr.push({
          label: `${index + 1}、${knowledgeTaskPageTypeValTitle[pageTypeVal]}`,
          value: key
        });
      }
    });
    return arr;
  });

  function initReqs() {
    initKnowledgeInfo();
  }
  initReqs();

  const delete_dialogue_ids = ref([]);
  function handleConversationItemDel({ savedID }) {
    savedID && delete_dialogue_ids.value.push(savedID);
  }
</script>