<template>
  <div>
    <ExerciseItem
      v-for="(item, index) in exerciseDataArr"
      :key="item.id"
      :item-index="index"
      :exercise-data="item"
      @add-click="handleAfterAdd"
      @exercise-del="handleExerciseDel"
      @exercise-pre="handleSeqPre"
      @exercise-next="handleSeqNext"
      @exercise-top="handleSeqTop"
      @exercise-bottom="handleSeqBottom"
    />
    <div style="padding-top: 30px; text-align: center;">
      <NButton @click="handleRandomAdd">随机添加题目</NButton>
      <NButton @click="handleLibraryAdd" style="margin-left: 15px;">添加题目</NButton>
    </div>

    <PageLoading :loading="loading" />
    <RandomAdd
      ref="randomAddModalRef"
      @exercise-id-add="handleAddRandomExercises"
      :exercise-id-all="[]"
    />
    <AddExercisesModal
      ref="libraryAddModalRef"
      @exercise-id-add="handleAddLibraryExercises"
    />
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { NButton } from 'naive-ui';

  import PageLoading from '@/components/PageLoading/index.vue';
  import ExerciseItem from '@/components/ExerciseItem/ExerciseItem.vue';
  import AddExercisesModal from '@/components/AddExercisesModal/AddExercisesModal.vue';
  import RandomAdd from '@/components/RandomAdd/index.vue';

  import { resStatusEnum } from '@/enumerators/http.js';
  import { resourceEnum as exerciseTypeVal } from '@/enumerators/exercises-types-map.js';

  import { generateExerciseItemData, getExerciseItemDataIdArr } from '../../generate-data.js';

  import { getExercises } from '@/api/paper.js';

  const props = defineProps({
    exerciseDataArr: {
      type: Array,
      default: () => []
    }
  });

  const { SUCCESS } = resStatusEnum;
  const loading = ref(false);

  let afterIndex;
  function reqExerciseDatas(idArr) {
    return new Promise(resolve => {
      idArr = idArr.filter(id => Boolean(id));
      if (idArr.length === 0) {
        resolve([]);
      } else {
        loading.value = true;
        getExercises({
          'Paper[exercises_ids]': idArr.join(',')
        }).then(({ code, data }) => {
          if (code === SUCCESS && Array.isArray(data)) {
            resolve(data.map(item => generateExerciseItemData(item)));
          }
        }).catch(err => {
          console.log(err);
          resolve([]);
        }).finally(() => {
          loading.value = false;
        });
      }
    });
  }
  function openLibraryAddModal() {
    libraryAddModalRef.value.changeIsHomework(1);
    libraryAddModalRef.value.openModal({
      key: exerciseTypeVal.TEACHING_RESOURCE_TYPE,
      filterIds: getExerciseItemDataIdArr(props.exerciseDataArr),
      // initIds: []
    });
  }
  
  function handleAfterAdd(index) {
    afterIndex = index + 1;
    openLibraryAddModal();
  }
  function handleExerciseDel(index) {
    const arr = props.exerciseDataArr;
    arr.splice(index, 1);
  }
  function handleSeqPre(index) {
    const arr = props.exerciseDataArr;
    if (index > 0) {
      const obj = arr.splice(index, 1)[0];
      arr.splice(index - 1, 0, obj);
    }
  }
  function handleSeqNext(index) {
    const arr = props.exerciseDataArr;
    if (index < arr.length - 1) {
      const obj = arr.splice(index, 1)[0];
      arr.splice(index + 1, 0, obj);
    }
  }
  function handleSeqTop(index) {
    const arr = props.exerciseDataArr;
    if (index > 0) {
      const obj = arr.splice(index, 1)[0];
      arr.splice(0, 0, obj);
    }
  }
  function handleSeqBottom(index) {
    const arr = props.exerciseDataArr;
    if (index < arr.length - 1) {
      const obj = arr.splice(index, 1)[0];
      arr.splice(arr.length, 0, obj);
    }
  }

  const randomAddModalRef = ref(null);
  const libraryAddModalRef = ref(null);
  function handleRandomAdd() {
    afterIndex = props.exerciseDataArr.length;
    randomAddModalRef.value.updateExerciseIdAll(getExerciseItemDataIdArr(props.exerciseDataArr));
    randomAddModalRef.value.openModalRandom();
  }
  function handleAddRandomExercises(idArr) {
    reqExerciseDatas(idArr).then(list => {
      props.exerciseDataArr.splice(afterIndex, 0, ...list);
    });
  }
  function handleLibraryAdd() {
    afterIndex = props.exerciseDataArr.length;
    openLibraryAddModal();
  }
  function handleAddLibraryExercises({ ids: idArr }) {
    reqExerciseDatas(idArr).then(list => {
      props.exerciseDataArr.splice(afterIndex, 0, ...list);
    });
  }
</script>