<template>
  <div class="page-list-box" :style="{ width: `${listBoxWidth}px` }">
    <NCard
      size="small"
      title="页面"
      :segmented="{ content: true }"
    >
      <template #header-extra>
        <PageAddBtn @page-add="emit('page-add', $event)" />
      </template>
      <NScrollbar style="height: calc(100vh - 297px);">
        <VueDraggableNext
          @end="handleDragEnd"
        >
          <div
            v-for="(item, index) in pageList"
            :key="item.key"
            class="page-item"
            :class="{ current: currentPageIndex === index, error: item.error }"
            @click="emit('current-page-index-change', index)"
          >
            <div class="serial-no primary-color">{{ index + 1 }}、</div>
            <PageInList :pageTypeVal="item.pageTypeVal" />
            <PageDelBtn class="del-btn" @confirm-del="emit('confirm-page-del', index)" />
          </div>
        </VueDraggableNext>
      </NScrollbar>
    </NCard>
  </div>
</template>

<script setup>
  import { NCard, NScrollbar } from 'naive-ui';
  import { VueDraggableNext } from "vue-draggable-next";

  import PageAddBtn from './PageAddBtn.vue'
  import PageInList from './PageInList.vue'
  import PageDelBtn from './PageDelBtn.vue'

  const emit = defineEmits(['page-add', 'current-page-index-change', 'confirm-page-del']);
  const props = defineProps({
    listBoxWidth: {
      type: Number,
      default: 200
    },
    pageList: {
      type: Array,
      default: () => []
    },
    currentPageIndex: {
      required: true,
      type: Number
    }
  });

  function handleDragEnd({ oldIndex, newIndex }) {
    if (oldIndex === newIndex) {
      return;
    }
    const pageListLink = props.pageList;
    const dragItem = pageListLink.splice(oldIndex, 1)[0];
    pageListLink.splice(newIndex, 0, dragItem);

    const currentPageIndexCopy = props.currentPageIndex;
    if (oldIndex < currentPageIndexCopy && newIndex >= currentPageIndexCopy) {
      emit('current-page-index-change', currentPageIndexCopy - 1);
    } else if (oldIndex > currentPageIndexCopy && newIndex <= currentPageIndexCopy) {
      emit('current-page-index-change', currentPageIndexCopy + 1);
    } else if (oldIndex === currentPageIndexCopy) {
      emit('current-page-index-change', newIndex);
    }
  }
</script>

<style lang="less" scoped>
  @import "~@/styles/variables.less"; 

  .page-list-box {
    :deep(.n-card-header), :deep(.n-card__content) {
      padding: 8px 10px;
    }
  }

  .page-item {
    position: relative;
    margin-bottom: 8px;
    border-radius: 4px;
    border: 2px solid transparent;
    overflow: hidden;

    &:last-child {
      margin-bottom: 0;
    }

    &.error {
      border-color: @error-color;
    }

    &.current {
      border-color: @primary-color;
    }

    &.sortable-ghost {
      border-color: @success-color;
      border-style: dashed;
    }
  }

  .serial-no {
    position: absolute;
    left: 7px;
    top: 5px;
    z-index: 1;
    font-size: 12px;
  }

  .del-btn {
    position: absolute;
    top: 6px;
    right: 6px;
  }
</style>