<template>
  <div class="ques-item">
    <div class="serial-no primary-color">{{ itemIndex + 1 }}、</div>
    <div class="ques-item-left">
      <NFormItem
        label="问题题干："
        :rule="formItemRules.quesRules"
        :path="`items[${itemIndex}].ques`"
      >
        <NInput
          type="textarea"
          v-model:value="itemData.ques"
        />
      </NFormItem>
      <NFormItem
        v-if="itemData.optionVal === conversationQuesVal.QUES_ANS"
        label="作答答案："
        :rule="formItemRules.inputAnsRules"
        :path="`items[${itemIndex}].ans`"
      >
        <NInput
          type="textarea"
          v-model:value="itemData.ans"
        />
      </NFormItem>
      <NFormItem
        v-else-if="itemData.optionVal === conversationQuesVal.SELECT"
        label="作答选项："
        show-require-mark
        :rule="formItemRules.checkBoxAnsRules"
        :path="`items[${itemIndex}].options`"
        ref="optionsFormItemRef"
      >
        <div>
          <NFormItem
            v-for="(item, index) in itemData.options"
            :key="item.key"
            :rule="formItemRules.optionRules"
            :path="`items[${itemIndex}].options[${index}]`"
          >
            <span>{{ item.label }}、</span>
            <NInput
              style="width: 300px;"
              clearable
              v-model:value="item.value"
            />
            <NIcon size="22" style="margin: 0 20px 0 10px;" @click="handleSelectAns(item)">
              <CheckBoxRound
                :color="themeSettings.primaryColor"
                v-if="item.checked"
              />
              <CheckBoxOutlineBlankFilled
                color="#ccc"
                v-else
              />
            </NIcon>
            <NButton
              v-if="itemData.options.length > 2"
              type="error"
              text
              @click="delOption(index)"
            >删除</NButton>
          </NFormItem>
          <NButton type="primary" text @click="addOption">添加选项</NButton>
        </div>
      </NFormItem>
      <SkillsFormItem
        :autoInitSkillList="false"
        :initSkillList="skillList"
        :formItemValuePath="`items[${itemIndex}]`"
        :skills="itemData.skills"
      />
    </div>
    <div class="ques-item-right">
      <NButton type="error" size="small" ghost @click="emit('del-click')">删除</NButton>
      <NButton type="success" size="small" ghost @click="emit('prev-click')">上移</NButton>
      <NButton type="success" size="small" ghost @click="emit('next-click')">下移</NButton>
      <NButton type="warning" size="small" ghost @click="emit('top-click')">最前</NButton>
      <NButton type="warning" size="small" ghost @click="emit('bottom-click')">最后</NButton>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { NFormItem, NInput, NButton, NIcon } from 'naive-ui';
  import { CheckBoxRound, CheckBoxOutlineBlankFilled } from '@vicons/material';

  import SkillsFormItem from '../SkillsFormItem/SkillsFormItem.vue'

  import themeSettings from '@/settings/theme-settings.js';
  import {
    conversationQuesVal,
    generateAnsOption
  } from '../../generate-data.js';

  const emit = defineEmits(['del-click', 'prev-click', 'next-click', 'top-click', 'bottom-click']);
  const props = defineProps({
    skillList: {
      type: Array,
      default: () => []
    },
    itemIndex: {
      required: true,
      type: Number
    },
    itemData: {
      required: true,
      type: Object
    }
  });

  const formItemRules = {
    quesRules: {
      required: true,
      message: '必填',
      trigger: 'blur'
    },
    inputAnsRules: {
      required: true,
      message: '必填',
      trigger: 'blur'
    },
    checkBoxAnsRules: {
      validator: (rule, options) => {
        if (options.filter(({ checked }) => checked).length === 0) {
          return new Error('请勾选正确答案');
        }
        return true;
      },
      trigger: 'change'
    },
    optionRules: {
      validator: (rule, { value }) => {
        if (!value) {
          return new Error('必填');
        }
        return true;
      },
      trigger: 'blur'
    }
  };

  function generateLabel(index) {
    return String.fromCharCode(65 + index);
  }

  const optionsFormItemRef = ref(null);
  function handleSelectAns(item) {
    item.checked = !item.checked;
    optionsFormItemRef.value.validate({
      trigger: 'change'
    });
  }

  function delOption(index) {
    const options = props.itemData.options;
    options.splice(index, 1);
    options.forEach((item, index) => {
      item.label = generateLabel(index);
    });
  }
  function addOption() {
    const options = props.itemData.options;
    options.push(
      generateAnsOption('isCreate', { label: generateLabel(options.length), value: '', checked: false })
    );
  }
</script>

<style lang="less" scoped>
  .ques-item {
    position: relative;
  }

  .serial-no {
    position: absolute;
    left: -15px;
    top: 0;
    z-index: 1;
  }

  .ques-item-left {
    margin: 0 67px 0 10px;
  }

  .ques-item-right {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;

    :deep(.n-button) {
      margin-bottom: 4px;

      &:first-child {
        margin-bottom: 15px;
      }
    }
  }
</style>