<template>
  <NDropdown
    trigger="click"
    placement="bottom-end"
    :show-arrow="true"
    :options="options"
    @select="handleSelect"
  >
    <NButton type="primary" size="tiny" circle ghost>
      <template #icon>
        <NIcon>
          <AddRound />
        </NIcon>
      </template>
    </NButton>
  </NDropdown>
</template>

<script setup>
  import { h } from 'vue';
  import { NDropdown, NButton, NIcon } from 'naive-ui';
  import { AddRound } from '@vicons/material';
  import {
    ImageOutlined,
    OndemandVideoFilled,
    ChatBubbleOutlineFilled,
    FormatListBulletedFilled,
    NoteAltOutlined
  } from '@vicons/material';

  import { knowledgeTaskPageTypeValTitle, knowledgeTaskPageTypeValIconName } from '@/enums/knowledge-task-page-type.js';

  const emit = defineEmits(['page-add']);

  const iconComps = {
    ImageOutlined,
    OndemandVideoFilled,
    ChatBubbleOutlineFilled,
    FormatListBulletedFilled,
    NoteAltOutlined
  };
  function renderIcon(iconComp) {
    return () => h(
      NIcon,
      null,
      {
        default: () => h(iconComps[iconComp])
      }
    );
  }
  const options = Object.keys(knowledgeTaskPageTypeValTitle)
                    .map(val => ({
                      label: knowledgeTaskPageTypeValTitle[val],
                      key: Number(val),
                      icon: renderIcon(knowledgeTaskPageTypeValIconName[val])
                    }));

  function handleSelect(key) {
    emit('page-add', key);
  }
</script>