<template>
  <div>
    <NModal
      preset="dialog"
      :show-icon="false"
      :mask-closable="false"
      style="width: 600px;"
      :title="modalTitle"
      v-model:show="modalShow"
      positive-text="提交"
      negative-text="取消"
      @positive-click="handleSubmit"
      @after-leave="closeModal"
    >
      <component
        v-if="formType"
        ref="formRef"
        :is="formComps[formType]"
        :formType="formType"
        :skillList="skillList"
        :pageOptions="pageOptions"
        :isFirstPage="isFirstPage"
        :isLastPage="isLastPage"
      ></component>
    </NModal>
  </div>
</template>

<script setup>
  import { ref, computed, nextTick } from 'vue';
  import { NModal } from 'naive-ui';

  import InputSet from './InputSet.vue';
  import SelectSet from './SelectSet.vue';
  import SubmitSet from './SubmitSet.vue';

  import { formItemTypeVal } from '../../generate-data.js';

  defineProps({
    skillList: {
      type: Array,
      default: () => []
    },
    pageOptions: {
      type: Array,
      default: () => []
    },
    isFirstPage: {
      type: Boolean,
      default: false
    },
    isLastPage: {
      type: Boolean,
      default: false
    }
  });

  const modalShow = ref(false);
  const modalTitle = computed(() => {
    switch (formType.value) {
      case formItemTypeVal.COMMENT:
        return '注释设置';
      case formItemTypeVal.SUBMIT:
        return '按钮设置';
      default:
        return '答案分数设置';
    }
  });

  function handleSubmit() {
    formRef.value.getFormValue().then(data => {
      Object.assign(storeItem, data, { valid: true, error: false });
      closeModal();
    });
    return false;
  }
  function closeModal() {
    modalShow.value = false;

    storeItem.active = true;
  }

  const formType = ref(null);
  const formComps = {};
  function initFormComps() {
    formComps[formItemTypeVal.INPUT] = InputSet;
    formComps[formItemTypeVal.TEXTAREA] = InputSet;
    formComps[formItemTypeVal.SELECT] = SelectSet;
    formComps[formItemTypeVal.RADIO_GROUP] = SelectSet;
    formComps[formItemTypeVal.CHECKBOX_GROUP] = SelectSet;
    formComps[formItemTypeVal.SUBMIT] = SubmitSet;
    formComps[formItemTypeVal.COMMENT] = SubmitSet;
  }
  initFormComps();

  let storeItem = null;
  const formRef = ref(null);

  defineExpose({
    openEdit(item) {
      storeItem = item;
      formType.value = item.type;

      modalShow.value = true;

      nextTick(() => {
        const copedItem = { ...item };
        Array.isArray(item.answer) && (copedItem.answer = JSON.parse(JSON.stringify(item.answer)));
        Array.isArray(item.options) && (copedItem.options = JSON.parse(JSON.stringify(item.options)));
        item.skills && (copedItem.skills = JSON.parse(JSON.stringify(item.skills)));
        formRef.value.setFormValue(copedItem);
      });
    }
  });
</script>