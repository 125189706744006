<template>
  <div style="padding: 30px 0;">
    <NForm
      ref="formRef"
      label-placement="left"
      label-width="105"
      require-mark-placement="left"
      :model="pageData"
    >
      <SkillsFormItem
        :skills="pageData.skills"
      />
      <NFormItem label="内容：" show-require-mark>
        <div style="width: 100%; padding-right: 25px;">
          <WangEditor5
            ref="wangEditor5Ref"
            editorAreaHeightStr="calc(100vh - 330px)"
            v-model:htmlValue="pageData.htmlValue"
            @pic-select="emit('pic-txt-content-pic-select')"
          />
        </div>
      </NFormItem>
    </NForm>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { NForm, NFormItem } from 'naive-ui';

  import SkillsFormItem from './SkillsFormItem/SkillsFormItem.vue'
  import WangEditor5 from '@/components/WangEditor5/WangEditor5.vue';

  const emit = defineEmits(['pic-txt-content-pic-select']);
  const props = defineProps({
    pageData: {
      type: Object,
      default: () => {}
    }
  });

  const formRef = ref(null);
  const wangEditor5Ref = ref(null);
  defineExpose({
    insertAPic(picURL) {
      wangEditor5Ref.value.insertAPic(picURL);
    }
  });
</script>