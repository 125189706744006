import { knowledgeTaskPageTypeVal } from '@/enums/knowledge-task-page-type.js';
import { conversationEditBtnTypeVal, conversationQuesVal } from './generate-data.js';

const message = window.$message;

let isPageContentErr = false;

export const validatePage = {};
validatePage[knowledgeTaskPageTypeVal.FORM] = pageData => {
  let valid = true;
  let errMsg = '';
  const { bgPicURL, formItems } = pageData;
  if (!bgPicURL) {
    valid = false;
    errMsg = '请先上传背景图';
  } else if (formItems.length === 0) {
    valid = false;
    errMsg = '请添加表单项';
  } else {
    formItems.forEach(item => {
      item.error = !item.valid;
      if (!item.valid) {
        if (valid) {
          valid = false;
          errMsg = '请完善表单项内容';
        }
      }
    });
  }
  pageData.error = !valid;
  pageData.errMsg = errMsg;
  return valid;
};
validatePage[knowledgeTaskPageTypeVal.CONVERSATION] = pageData => {
  let valid = true;
  let errMsg = '';
  const { scenesType, items } = pageData;
  if (!scenesType) {
    valid = false;
    errMsg = '请设置场景';
  } else if (items.length === 0) {
    valid = false;
    errMsg = '请添加对话/问题';
  } else {
    let i = 0;
    let l = items.length;
    for (; i < l; i++) {
      const item = items[i];
      const { editBtnType, optionVal } = item;
      if (editBtnType === conversationEditBtnTypeVal.CHAT) {
        if (!item.talkTxt) {
          valid = false;
          errMsg = '请完善内容';
          break;
        }
        /*
        if (!item.talkFile.fileUrl) {
          valid = false;
          errMsg = '请完善内容';
          break;
        }
        */
      } else if (editBtnType === conversationEditBtnTypeVal.QUES_ANS) {
        if (!item.ques) {
          valid = false;
          errMsg = '请完善内容';
          break;
        }
        if (item.skills.some(({ skillID, score }) => (!skillID || !score))) {
          valid = false;
          errMsg = '请完善内容';
          break;
        }
        if (optionVal === conversationQuesVal.QUES_ANS) {
          if (!item.ans) {
            valid = false;
            errMsg = '请完善内容';
            break;
          }
        } else if (optionVal = conversationQuesVal.SELECT) {
          if (item.options.some(({ value }) => !value)) {
            valid = false;
            errMsg = '请完善内容';
            break;
          }
          if (item.options.filter(({ checked }) => checked).length === 0) {
            valid = false;
            errMsg = '请完善内容';
            break;
          }
        }
      }
    }
  }

  pageData.error = !valid;
  pageData.errMsg = errMsg;
  return valid;
};
validatePage[knowledgeTaskPageTypeVal.VIDEO] = pageData => {
  let valid = true;
  let errMsg = '';
  const { videoData, skills } = pageData;
  if (!videoData.fileUrl) {
    valid = false;
    errMsg = '请上传视频';
  } else if (skills.some(({ skillID, score }) => (!skillID || !score))) {
    valid = false;
    errMsg = '请完善能力分';
  }
  pageData.error = !valid;
  pageData.errMsg = errMsg;
  return valid;
};
validatePage[knowledgeTaskPageTypeVal.PIC_TXT] = pageData => {
  let valid = true;
  let errMsg = '';
  const { skills } = pageData;
  if (skills.some(({ skillID, score }) => (!skillID || !score))) {
    valid = false;
    errMsg = '请完善能力分';
  }
  pageData.error = !valid;
  pageData.errMsg = errMsg;
  return valid;
};
validatePage[knowledgeTaskPageTypeVal.EXERCISES] = pageData => {
  let valid = true;
  let errMsg = '';
  const { skills, exercises } = pageData;
  if (skills.some(({ skillID, score }) => (!skillID || !score))) {
    valid = false;
    errMsg = '请完善能力分';
  } else if (exercises.length === 0) {
    valid = false;
    errMsg = '请添加习题';
  }
  pageData.error = !valid;
  pageData.errMsg = errMsg;
  return valid;
};

export function validateTaskData({ recommendMins, pageList }) {
  let valid = true;
  isPageContentErr = false;
  if (!(recommendMins || recommendMins === 0)) {
    message.error('请填写建议时长');
    return false;
  }
  if (pageList.length === 0) {
    message.error('请添加页面');
    return false;
  }
  isPageContentErr = true;
  pageList.forEach(item => {
    const b = validatePage[item.pageTypeVal](item);
    valid && (valid = b);
  });
  return valid;
}

export function tipCurPageErr(curPageData) {
  if (!isPageContentErr) {
    return;
  }
  if (curPageData && curPageData.error) {
    message.error(curPageData.errMsg);
  } else {
    message.error('有页面内容未完善');
  }
}