<template>
  <div class="np-box">
    <h3 class="np-title">便签</h3>
    <NScrollbar style="height: 100%;">
      <div class="np-list">
        <div
          v-for="(item, index) in notePadArr"
          :key="item.key"
          class="np-item"
          :title="item.title"
          @click="handleClickEdit(item)"
        >
          <NIcon size="24">
            <NoteAltOutlined />
          </NIcon>
          <NIcon class="np-del" @click.stop="handleClickDel(index)">
            <CloseOutlined />
          </NIcon>
        </div>
        <div class="np-add" @click="handleClickAdd">
          <NIcon size="24">
            <AddRound />
          </NIcon>
        </div>
      </div>
    </NScrollbar>

    <NotePadAddModal
      ref="notePadAddModalRef"
      @item-add="handleAddNotePad"
    />
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { NScrollbar, NIcon } from 'naive-ui';
  import { NoteAltOutlined, AddRound, CloseOutlined } from '@vicons/material';

  import NotePadAddModal from './NotePadAddModal.vue';

  const props = defineProps({
    notePadArr: {
      type: Array,
      default: () => []
    }
  });

  const notePadAddModalRef = ref(null);

  function handleClickEdit(item) {
    notePadAddModalRef.value.openEdit(item);
  }
  function handleClickDel(index) {
    props.notePadArr.splice(index, 1);
  }
  function handleClickAdd() {
    notePadAddModalRef.value.openCreate();
  }
  function handleAddNotePad(item) {
    props.notePadArr.push(item);
  }
</script>

<style lang="less" scoped>
  .np-box {
    width: 65px;
    padding-top: 32px;
  }

  .np-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    line-height: 42px;
    margin: 0;
    text-align: center;
    font-size: 14px;
  }

  .np-list {
    padding: 10px 0;
  }

  .np-item, .np-add {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 35px;
    margin: 0 auto;
    border-radius: 4px;
    border: 1px solid #999;
    text-align: center;
    cursor: pointer;
  }

  .np-item {
    position: relative;
    margin-bottom: 10px;
    color: #666;
  }

  .np-del {
    position: absolute;
    top: -5px;
    right: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    border: 1px solid #333;
    background-color: #fff;
    font-size: 13px;
    transition: all .3s;

    &:hover {
      color: #fff;
      background-color: #333;
    }
  }

  .np-add {
    border-style: dashed;
    color: #999;
  }
</style>