<template>
  <div style="padding: 30px 0;">
    <NForm
      ref="formRef"
      label-placement="left"
      label-width="105"
      require-mark-placement="left"
      :model="pageData"
    >
      <SkillsFormItem
        :skills="pageData.skills"
      />
      <NFormItem label="习题：" show-require-mark>
        <EditExercises
          style="width: 100%; padding-right: 25px;"
          :exerciseDataArr="pageData.exercises"
        />
      </NFormItem>
    </NForm>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { NForm, NFormItem } from 'naive-ui';

  import SkillsFormItem from './SkillsFormItem/SkillsFormItem.vue'
  import EditExercises from './EditExercises/EditExercises.vue'

  const props = defineProps({
    pageData: {
      type: Object,
      default: () => {}
    }
  });

  const formRef = ref(null);
  
  defineExpose({
    
  });
</script>