<template>
  <div class="rm-box">
    <span class="rm-require-mark error-color">*</span>
    <span class="rm-title">建议时长（min）:</span>
    <NInputNumber
      :show-button="false"
      :min="0"
      clearable
      class="rm-input-box"
      placeholder=""
      :value="mins"
      @update:value="handleInput"
      @focus="handleFocus"
      @blur="handleBlur"
    />
  </div>
</template>

<script setup>
  import { NInputNumber } from 'naive-ui';

  defineProps(['mins']);
  const emit = defineEmits(['update:mins']);

  let changedMins = null;
  function handleFocus() {
    changedMins = null;
  }
  function handleBlur() {
    if (changedMins || changedMins === 0) {
      emit('update:mins', Math.round(changedMins));
    }
  }
  function handleInput(val) {
    changedMins = val;
    emit('update:mins', val);
  }
</script>

<style lang="less" scoped>
  .rm-box, .rm-input-box {
    display: inline-block;
    vertical-align: top;
  }

  .rm-box {
    margin: 8px 40px 0 0;
  }

  .rm-require-mark, .rm-title {
    line-height: 34px;
  }

  .rm-title {
    margin: 0 10px 0 3px;
  }

  .rm-input-box {
    width: 100px;
  }
</style>