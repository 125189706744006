<template>
  <div style="width: 100%; border: 1px solid #ccc;">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editorRef"
      :defaultConfig="toolbarConfig"
      mode="default"
    />
    <Editor
      :style="{ height: `${editorAreaHeightStr}` }"
      :defaultConfig="defaultEditConfig"
      mode="default"
      :modelValue="htmlValue"
      @update:modelValue="emit('update:htmlValue', $event)"
      @onCreated="handleCreated"
      @onChange="handleContentChange"
    />
  </div>
</template>

<script setup>
  import '@wangeditor/editor/dist/css/style.css';
  import { ref, shallowRef, computed, onMounted, onBeforeUnmount, nextTick } from 'vue';
  import { Editor, Toolbar } from '@wangeditor/editor-for-vue';

  const emit = defineEmits(['update:htmlValue', 'pic-select']);
  const props = defineProps({
    toolbarConfig: {
      type: Object,
      default: {
        excludeKeys: ['group-video', 'emotion']
      }
    },
    editorConfig: {
      type: Object,
      default: null
    },
    editorAreaHeightStr: {
      type: String,
      default: '200px'
    },
    htmlValue: {
      type: String,
      default: '<p><br></p>'
    }
  });

  const editorRef = shallowRef(null);
  const defaultEditConfig = computed(() => {
    return props.editorConfig || {
      placeholder: '请输入内容...',
      MENU_CONF: {
        uploadImage: {
          customBrowseAndUpload(insertFn) {
            // insertFn(url, alt, href)
            currentInsertFn = insertFn;
            emit('pic-select');
          }
        }
      }
    };
  });

  function handleCreated(editor) {
    editorRef.value = editor;
  }
  function handleContentChange(editor) {
    // console.log(editor.getText());
  }

  onMounted(() => {
    
  });
  onBeforeUnmount(() => {
    const editor = editorRef.value;
    if (editor) {
      editor.destroy();
    }
  });

  let currentInsertFn = null;
  defineExpose({
    insertAPic(picURL) {
      if (currentInsertFn) {
        currentInsertFn(picURL);
        currentInsertFn = null;
      }
    }
  });
</script>