<template>
  <div>
    <NModal
      preset="dialog"
      :show-icon="false"
      :mask-closable="false"
      style="width: 450px;"
      :title="modalTitle"
      v-model:show="modalShow"
      positive-text="提交"
      negative-text="取消"
      @positive-click="handleSubmit"
      @after-leave="closeModal"
    >
      <NForm
        ref="formRef"
        :rules="formRules"
        :model="formValue"
        style="padding: 20px 0 0;"
      >
        <NFormItem label="便签标题：" path="title">
          <NInput
            clearable
            maxlength="20"
            show-count
            v-model:value="formValue.title"
          />
        </NFormItem>
        <NFormItem label="便签内容：" path="content">
          <NInput
            type="textarea"
            clearable
            maxlength="1000"
            show-count
            v-model:value="formValue.content"
          />
        </NFormItem>
      </NForm>
    </NModal>
  </div>
</template>

<script setup>
  import { ref, reactive } from 'vue';
  import { NModal, NForm, NFormItem, NInput } from 'naive-ui';

  import { generateFormNotePadData } from '../../generate-data.js';

  const emit = defineEmits(['item-add']);

  const modalTitle = ref('新增便签');
  const modalShow = ref(false);

  const formRef = ref(null);
  const formRules = {
    title: {
      required: true,
      message: '请填写便签标题',
      trigger: 'blur'
    },
    content: {
      required: true,
      message: '请填写便签内容',
      trigger: 'blur'
    }
  };
  function getDefaultFormValue() {
    return {
      title: '',
      content: ''
    }
  }
  let otherNotePadData = {};
  const formValue = reactive(getDefaultFormValue());

  function handleSubmit() {
    formRef.value.validate(errors => {
      if (!errors) {
        if (isAdd) {
          emit('item-add', Object.assign(otherNotePadData, formValue));
        } else {
          Object.assign(otherNotePadData, formValue);
        }

        closeModal();
      }
    });
    return false;
  }
  function resetFormValue() {
    Object.assign(formValue, getDefaultFormValue());
  }
  function closeModal() {
    resetFormValue();
    modalShow.value = false;
  }

  let isAdd = false;

  defineExpose({
    openCreate() {
      isAdd = true;
      modalTitle.value = '新增便签';
      otherNotePadData = generateFormNotePadData();
      modalShow.value = true;
    },
    openEdit(item) {
      isAdd = false;
      modalTitle.value = '编辑便签';
      otherNotePadData = item;
      const { title, content } = item;
      Object.assign(formValue, {
        title,
        content
      });
      modalShow.value = true;
    }
  });
</script>